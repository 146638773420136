export interface GroupsCollectionI {
    id: number;
    createdAt: Date;
    name: string;
    groups: string;
  }
  
  export class GroupsCollection implements GroupsCollectionI {
    id: number;
    createdAt: Date;
    name: string;
    groups: string;
  
    constructor(groupsCollectionI: GroupsCollectionI) {
      this.id = groupsCollectionI.id;
      this.createdAt = groupsCollectionI.createdAt;
      this.name = groupsCollectionI.name;
      this.groups = groupsCollectionI.groups;
    }
  }
  