// src/interfaces/review-pagination.interface.ts

import { Review } from '../review/review';

export interface ReviewPaginationEntityI {
  reviews: Review[];
  limit: number;
  page: number;
  totalReviews: number;
  totalPages: number;
}

export class ReviewPaginationEntity implements ReviewPaginationEntityI {
    reviews: Review[];
    limit: number;
    page: number;
    totalReviews: number;
    totalPages: number;
  
    constructor(pagination: ReviewPaginationEntityI) {
      this.reviews = pagination.reviews;
      this.limit = pagination.limit;
      this.page = pagination.page;
      this.totalReviews = pagination.totalReviews;
      this.totalPages = pagination.totalPages;
    }
  }