import axios from 'axios';
import { getToken } from '../utils/token';
import { User, UserI } from '../interfaces/user';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getUserByIdApi(id: number): Promise<User> {
  const url = `${envUrl}/users/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .get(url, config)
    .then((response) => new User(response.data as UserI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function getUsersByBranchApi(branchId: number): Promise<User[]> {
  const url = `${envUrl}/users/branch/${branchId}`;
  const token = await getToken();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => response.data.map((user: UserI) => new User(user)))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function getUsersByBusinessApi(
  businessId: number,
): Promise<User[]> {
  const url = `${envUrl}/users/business/${businessId}`;
  const token = await getToken();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => response.data.map((user: UserI) => new User(user)))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function patchUserByIdApi(id: number, data: Partial<User>): Promise<User> {
  const url = `${envUrl}/users/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .patch(url, data, config)
    .then((response) => new User(response.data as UserI))
    .catch((err) => {
      throw new Error(err);
    });
}

export async function patchUsersByBranchApi(
  branchId: number,
  data: { users: Partial<Number>[] },
): Promise<User[]> {
  const url = `${envUrl}/users/branch/${branchId}`;
  const token = await getToken();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .patch(url, data, config)
    .then((response) => response.data.map((user: UserI) => new User(user)))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}