import axios from 'axios';
import { getToken } from '../utils/token';
import { AuthEntity, AuthEntityI } from '../interfaces/auth';
import { User, UserI } from '../interfaces/user';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export function loginApi(email: string, password: string): Promise<AuthEntity> {
    const url = `${envUrl}/auth/login`;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return axios.post(url, { email, password }, config)
        .then(response => {
            return new AuthEntity(response.data as AuthEntityI); 
        })
        .catch(err => {
            throw new Error(err);
        });
}

export function signUpApi(
    username: string,
    email: string,
    password: string,
    businessName: string,
    businessDescription: string,
    branchName: string,
    branchAddress: string,
    branchGroups: string
): Promise<AuthEntity> {
    const url = `${envUrl}/auth/signup`;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return axios.post(url, {
        username,
        email,
        password,
        businessName,
        businessDescription,
        branchName,
        branchAddress,
        branchGroups
    }, config)
        .then(response => {
            return new AuthEntity(response.data as AuthEntityI); 
        })
        .catch(err => {
            throw new Error(err);
        });
}

export async function userByTokenApi(): Promise<User> {
    const url = `${envUrl}/auth/user`;

    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    return axios.get(url, config)
        .then(response => {
            return new User(response.data as UserI);
        })
        .catch(err => {
            throw new Error(err);
        });
}
