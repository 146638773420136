import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Chart } from "../chart";
import { useEffect, useState } from "react";
import { sentiments } from "../../utils/sentiments";
import React from "react";

const useChartOptions = () => {
  const theme = useTheme();

  return {
    colors: sentiments.colors,
    chart: {
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    labels: sentiments.tags,
    legend: {
      show: true,
      position: "bottom",
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      fillSeriesColor: false,
    },
  };
};

export const BySentimentPie = ({ sx, groupedBySentiment, total }) => {
  const labels = sentiments.tags;
  const chartOptions = useChartOptions();
  const [chartSeries, setChartSeries] = useState<number[]>([]);

  useEffect(() => {
    if (!groupedBySentiment) return;
    const series = labels.map((label) => groupedBySentiment[label] || 0);
    setChartSeries(series);
  }, [groupedBySentiment, labels]);

  if (!groupedBySentiment || total === 0) {
    return (
      <Card sx={sx}>
        <CardHeader title="Por Sentimiento" />
        <CardContent>
          <Typography variant="h6">No hay datos para mostrar</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={sx}>
      <CardHeader title="Por Sentimiento" />
      <CardContent>
        <Chart
          height={300}
          options={chartOptions}
          series={chartSeries}
          type="donut"
          width="100%"
        />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          sx={{ mt: 2 }}
          spacing={{ xs: 1, sm: 2 }}
          useFlexGap
          flexWrap="wrap"
        >
          {labels.map((label, index) => (
            <Box key={index}>
              <Typography sx={{ my: 1 }} variant="h6">
                {label}: {((chartSeries[index] * 100) / total).toFixed(0)}%
              </Typography>
            </Box>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};
