import { Attendant, AttendantI } from "./attendant";
import { Spot, SpotI } from "./spot";

export interface BranchPublicInfoI {
  name: string;
  attendants: AttendantI[];
  spots: SpotI[];
  enforceAttendants: boolean;
  enforceSpots: boolean;
}

export class BranchPublicInfo implements BranchPublicInfoI {
  name: string;
  attendants: Attendant[];
  spots: Spot[];
  enforceAttendants: boolean;
  enforceSpots: boolean;

  constructor(branchPublicInfoI: Partial<BranchPublicInfoI> = {}) {
    this.name = branchPublicInfoI.name ?? ''; 
    this.attendants = branchPublicInfoI.attendants ?? []; 
    this.spots = branchPublicInfoI.spots ?? []; 
    this.enforceAttendants = branchPublicInfoI.enforceAttendants ?? false; 
    this.enforceSpots = branchPublicInfoI.enforceSpots ?? false;
  }
}
