export interface BusinessI {
    id: number;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    stripeCustomerId: string;
    stripeStatus: string;
    description: string;
    adminId: number;
    status: boolean;
    enforceSpots: boolean
    enforceAttendants: boolean
  }
  
  export class Business implements BusinessI {
    id: number;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    stripeCustomerId: string;
    stripeStatus: string;
    description: string;
    adminId: number;
    status: boolean;
    enforceSpots: boolean
    enforceAttendants: boolean
  
    constructor(businessI: BusinessI) {
      this.id = businessI.id;
      this.createdAt = businessI.createdAt;
      this.updatedAt = businessI.updatedAt;
      this.name = businessI.name;
      this.stripeCustomerId = businessI.stripeCustomerId;
      this.stripeStatus = businessI.stripeStatus;
      this.description = businessI.description;
      this.adminId = businessI.adminId;
      this.status = businessI.status;
      this.enforceSpots = businessI.enforceSpots;
      this.enforceAttendants = businessI.enforceAttendants;
    }
  }
  