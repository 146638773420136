import { format } from "date-fns";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  SxProps,
  Theme,
} from "@mui/material";
import { Scrollbar } from "../scrollbar";
import { SeverityPill } from "../severity-pill";
import { useEffect, useState } from "react";
import { dayOfWeekMapper } from "../../utils/dayOfWeekMapper";
import ReviewModal from "../modals/reviewModal";
import { getReviewsApi } from "../../api/reviews";
import { Review } from "../../interfaces/review/review";
import React from "react";

interface TopReviewsTableProps {
  sx?: SxProps<Theme>;
  startDate: string;
  endDate: string;
  branchId: number;
  type: "positive" | "negative";
}

const statusMap: Record<Review["sentiment"], "warning" | "success" | "error"> =
  {
    Neutral: "warning",
    Positivo: "success",
    Negativo: "error",
  };

export const TopReviewsTable: React.FC<TopReviewsTableProps> = ({
  sx,
  startDate,
  endDate,
  branchId,
  type = "positive",
}) => {
  const [modalParentReviewId, setModalParentReviewId] = useState<
    number | undefined
  >();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [reviews, setReviews] = useState<Review[]>([]);

  const handleOpenModal = (rawReviewId: number): void => {
    setModalParentReviewId(rawReviewId);
    setOpenModal(true);
  };

  const handleCloseModal = (): void => setOpenModal(false);

  useEffect(() => {
    const fetchData = async (start: Date, end: Date): Promise<void> => {
      try {
        const data = await getReviewsApi(
          branchId,
          start,
          end,
          [],
          [],
          [type === "positive" ? "Positivo" : "Negativo"],
          type === "positive" ? "scoreAsc" : "scoreDesc",
        );
        setReviews(data.reviewsPage.reviews.slice(0, 5));
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    if (startDate !== "" && endDate !== "" && branchId) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      fetchData(start, end);
    }
  }, [startDate, endDate, branchId, type]);

  return (
    <>
      <Card sx={sx}>
        <CardHeader
          title={type === "positive" ? "Top Positivas" : "Top Negativas"}
          sx={{ padding: "15px" }}
        />
        <Scrollbar sx={{ flexGrow: 1 }}>
          <Box sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection="desc">Fecha</TableCell>
                  <TableCell>Sentimiento</TableCell>
                  <TableCell>Emoción</TableCell>
                  <TableCell>Resumen</TableCell>
                  <TableCell>Grupo</TableCell>
                  <TableCell>Puntaje</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reviews.map((review) => {
                  const date = format(
                    new Date(review.createdAt),
                    "dd/MM/yyyy HH:mm",
                  );
                  const dayOfTheWeek = format(
                    new Date(review.createdAt),
                    "EEEE",
                  );
                  return (
                    <TableRow hover key={review.id}>
                      <TableCell
                        onClick={() => handleOpenModal(review.rawReviewId)}
                      >
                        {dayOfWeekMapper[dayOfTheWeek]}, {date}
                      </TableCell>
                      <TableCell
                        onClick={() => handleOpenModal(review.rawReviewId)}
                      >
                        <SeverityPill color={statusMap[review.sentiment]}>
                          {review.sentiment}
                        </SeverityPill>
                      </TableCell>
                      <TableCell
                        onClick={() => handleOpenModal(review.rawReviewId)}
                      >
                        {review.emotion}
                      </TableCell>
                      <TableCell
                        onClick={() => handleOpenModal(review.rawReviewId)}
                      >
                        {review.summary}
                      </TableCell>
                      <TableCell
                        onClick={() => handleOpenModal(review.rawReviewId)}
                      >
                        {review.group}
                      </TableCell>
                      <TableCell
                        onClick={() => handleOpenModal(review.rawReviewId)}
                      >
                        {review.score}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
        <Divider />
      </Card>
      <ReviewModal
        handleClose={handleCloseModal}
        open={openModal}
        parentReviewId={modalParentReviewId}
      />
    </>
  );
};
