import { Business, BusinessI } from "./business";

export enum Role {
  ADMIN = "ADMIN",
  OWNER = "OWNER",
  MANAGER = "MANAGER",
}


export interface UserI {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  email: string;
  role: Role;
  status: boolean;
  businessId: number;
  business?: BusinessI;
  password?: string
}

export class User implements UserI {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  email: string;
  role: Role;
  status: boolean;
  businessId: number;
  business?: Business;
  password?: string


  constructor(partial: Partial<UserI>) {
    this.id = partial.id ?? 0;
    this.createdAt = partial.createdAt ?? new Date();
    this.updatedAt = partial.updatedAt ?? new Date();
    this.name = partial.name ?? "";
    this.email = partial.email ?? "";
    this.role = partial.role ?? Role.MANAGER;
    this.status = partial.status ?? true; 
    this.businessId = partial.businessId ?? 0;
    this.business = partial.business ? new Business(partial.business) : undefined;
    this.password = partial.password ?? "";

  }
}
