
export interface AttendantI{
    id: number
    createdAt: Date
    updatedAt: Date
    name: string
    status: boolean
    branchId: number
}

export class Attendant implements AttendantI{
    id: number
    createdAt: Date
    updatedAt: Date
    name: string
    status: boolean
    branchId: number

    constructor(attendantI: Partial<AttendantI>={}){
        this.id = attendantI.id ?? 0; 
        this.createdAt = attendantI.createdAt ?? new Date();
        this.updatedAt = attendantI.updatedAt ?? new Date();
        this.name = attendantI.name ?? '';
        this.status = attendantI.status ?? true; 
        this.branchId = attendantI.branchId ?? 0;
    }
} 