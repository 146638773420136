import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { DatesFilter } from '../cards/dateFilter';
import { useState } from 'react';
import { postReportApi } from '../../api/reports';
import { useAuthStore } from '../../hooks/useAuthStore';
import { ToastContainer, toast } from 'react-toastify';
import { ToastError, ToastSuccess } from '../shared/Toast';
import React from 'react';
import { Branch } from '../../interfaces/branch';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  p: 4,
};

type ReportModalProps={
  open: boolean
  handleClose: ()=> void
  handleSuccess: ()=> void
}

export default function ReportModal({ open, handleClose, handleSuccess }: ReportModalProps) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { currentBranch } = useAuthStore() as {currentBranch: Branch};

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const id = toast.loading('Cargando...');
    try {
      const startDateCasted=new Date(startDate)
      const endDateCasted=new Date(endDate)

      await postReportApi(currentBranch.id, startDateCasted, endDateCasted);
      setError('');
      handleSuccess();
      ToastSuccess(id, 'Reseña Procesada');
    } catch (error) {
      ToastError(id, String(error));
      setError(String(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Card>
            <DatesFilter handleFilter={handleDateChange} />
            <CardContent>
              <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Stack spacing={3}>
                  <Typography variant="body2">
                    Genera un reporte inteligente sobre las reseñas dentro del
                    peiodo comprendido.
                  </Typography>
                  {error && <Typography color="error">{error}</Typography>}
                </Stack>
                <Stack spacing={3}>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    Generar Reporte
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </div>
  );
}
