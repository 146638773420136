import { Review, ReviewI } from "./review";

export interface RawReviewI {
  id: number;
  review: string;
  createdAt: Date;
  branchId: number;
  processedReviews?: ReviewI[];
  attendantId?: number;
  spotId?: number;
}

export class RawReview implements RawReviewI {
  id: number;
  review: string;
  createdAt: Date;
  branchId: number;
  processedReviews?: Review[];
  attendantId?: number;
  spotId?: number;

  constructor(rawReview: Partial<RawReviewI>) {
    this.id = rawReview.id ?? 0;
    this.review = rawReview.review ?? '';
    this.createdAt = rawReview.createdAt ?? new Date();
    this.branchId = rawReview.branchId ?? 0;
    this.processedReviews = rawReview.processedReviews
      ? rawReview.processedReviews.map(review => new Review(review))
      : undefined;
    this.attendantId = rawReview.attendantId ?? undefined;
    this.spotId = rawReview.spotId ?? undefined;
  }
}
