import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { patchSpotApi, postSpotApi } from '../../api/spots';
import React from 'react';
import { Spot } from '../../interfaces/spot';

type NewSpotFormProps = {
  newSpot?: boolean;
  spot?: Spot;
  handleSuccess: () => void;
  branchId?: number;
};


export const NewSpotForm = ({
  newSpot = true,
  spot,
  handleSuccess,
  branchId,
}: NewSpotFormProps) => {
  const [modified, setModified] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      status: true,
      submit: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Inserta Nombre'),
      status: Yup.boolean(),
    }),
    onSubmit: async (values, helpers) => {
      const dataToPost = {
        ...values,
        branchId: branchId,
      };
      if (!newSpot) {
        delete dataToPost.branchId;
      }
      newSpot
        ? console.log('newSpot', dataToPost)
        : console.log('modifiedSpot', dataToPost);
      try {
        newSpot
          ? await postSpotApi({...dataToPost, branchId: branchId!})
          : await patchSpotApi(spot!.id, dataToPost);
        handleSuccess();
        setModified(false);
        helpers.resetForm();
      } catch (error) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!newSpot) {
      formik.setValues({
        name: spot?.name ? spot?.name : '',
        status: spot?.status ? spot?.status : false,
        submit: null,
      });
    }
    // eslint-disable-next-line
  }, [spot, newSpot]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader title={newSpot ? 'Nuevo Espacio' : 'Modificar Espacio'} />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Nombre del espacio"
                  name="name"
                  error={!!(formik.touched.name && formik.errors.name)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setModified(true);
                  }}
                  value={formik.values.name}
                />
              </Grid>
              {!newSpot && (
                <Grid xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.status}
                        value={formik.values.status}
                        name="status"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.handleChange(e);
                          setModified(true);
                        }}
                      />
                    }
                    label="Activo"
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          {newSpot && (
            <Button variant="contained" type="submit">
              Crear Espacio
            </Button>
          )}
          {!newSpot && modified && (
            <Button variant="contained" type="submit">
              Modificar Espacio
            </Button>
          )}
        </CardActions>
      </Card>
    </form>
  );
};
