import { format } from 'date-fns';
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Unstable_Grid2 as Grid,
  TablePagination,
} from '@mui/material';
import { Scrollbar } from '../scrollbar';
import { SeverityPill } from '../severity-pill';
import { useEffect, useState } from 'react';
import { dayOfWeekMapper } from '../../utils/dayOfWeekMapper';
import { colors } from '../../utils/colors';
import { CustomSeverityPill } from '../custom-severity-pill';
import ReviewModal from '../modals/reviewModal';
import { getReviewsApi } from '../../api/reviews';
import { getAttendantsByBranchApi } from '../../api/attendants';
import { getSpotsByBranchApi } from '../../api/spots';
import { ReviewResponseEntity } from '../../interfaces/review/review-response';
import React from 'react';
import { Attendant } from '../../interfaces/attendant';
import { Spot } from '../../interfaces/spot';
import { Review } from '../../interfaces/review/review';

const statusMap = {
  Neutral: 'warning',
  Positivo: 'success',
  Negativo: 'error',
};

interface AllReviewsTableProps {
  sx?: object; 
  startDate: string;
  endDate: string;
  branchId: number;
  bySentiment?: boolean;
  byGroup?: boolean;
  byEmotion?: boolean;
  ordering?: boolean;
  group?: string; 
}


export const AllReviewsTable: React.FC<AllReviewsTableProps> = ({
  sx,
  startDate,
  endDate,
  branchId,
  bySentiment = true,
  byGroup = true,
  byEmotion = true,
  ordering = true,
  group,
}) => {
  const [modalParentReviewId, setModalParentReviewId] = useState();
  const [filteredData, setFilteredData] = useState<Review[]>([]);
  const [totalFiltered, setTotalFiltered] = useState<number>(0);
  const [groups, setGroups] = useState<string[]>([]);
  const [emotions, setEmotions] = useState<string[]>([]);
  const [sentiments, setSentiments] = useState<string[]>([]);
  const [attendants, setAttendants] = useState<Attendant[]>([]);
  const [spots, setSpots] = useState<Spot[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [groupedBySentiment, setGroupedBySentiment] = useState({
    Negativo: 0,
    Positivo: 0,
    Neutral: 0,
  });

  const handleOpenModal = (rawReviewId) => {
    setModalParentReviewId(rawReviewId);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const [filterOptions, setFilterOptions] = useState({
    byDateAsc: false,
    byDateDesc: false,
    byScoreAsc: false,
    byScoreDesc: false,
    selectedGroups: [],
    selectedEmotions: [],
    selectedSentiments: [],
    selectedAttendants: [],
    selectedSpots: [],
  });

  // Fetch groups, sentiments and emotions
  // Since all of these are dynamic, we need to fetch them
  // every time the startDate, endDate or branchId changes
  useEffect(() => {
    const fetchData = async (start: Date, end: Date) => {
      try {
        const data: ReviewResponseEntity = await getReviewsApi(
          branchId,
          start,
          end,
        );
        setGroups(Object.keys(data.groupByGroup));
        setSentiments(Object.keys(data.groupBySentiment));
        setEmotions(Object.keys(data.groupByEmotion));
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
    if (startDate !== '' && endDate !== '' && branchId) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      fetchData(start, end);
    }
  }, [startDate, endDate, branchId]);

  // this should only be retrieved once since we want to have all
  // attendants and spots available for filtering
  useEffect(() => {
    const fetchAttendants = async () => {
      try {
        const attendants: Attendant[] = await getAttendantsByBranchApi(branchId);
        setAttendants(attendants);
      } catch (error) {
        console.error('Error fetching attendants:', error);
      }
    };
    const fetchSpots = async () => {
      try {
        const spots: Spot[] = await getSpotsByBranchApi(branchId);
        setSpots(spots);
      } catch (error) {
        console.error('Error fetching spots:', error);
      }
    };
    branchId && fetchAttendants();
    branchId && fetchSpots();
  }, [branchId]);

  // Fetched data changes everytime either the filters or the pagination changes
  useEffect(() => {
    const {
      byDateAsc,
      byDateDesc,
      byScoreAsc,
      byScoreDesc,
      selectedGroups,
      selectedEmotions,
      selectedSentiments,
      selectedAttendants,
      selectedSpots,
    } = filterOptions;

    let sort;
    if (byDateAsc) sort = 'dateAsc';
    if (byDateDesc) sort = 'dateDesc';
    if (byScoreAsc) sort = 'scoreAsc';
    if (byScoreDesc) sort = 'scoreDesc';

    const fetchData = async (start: Date, end: Date) => {
      try {
        const data: ReviewResponseEntity = await getReviewsApi(
          branchId,
          start,
          end,
          selectedEmotions,
          group ? [group] : selectedGroups,
          selectedSentiments,
          selectedAttendants,
          selectedSpots,
          sort,
          rowsPerPage,
          page
        );
        setFilteredData(data.reviewsPage.reviews);
        setTotalFiltered(data.reviewsPage.totalReviews);
        setGroupedBySentiment({
          Negativo: 0,
          Positivo: 0,
          Neutral: 0,
          ...data.groupBySentiment,
        });
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    if (startDate !== '' && endDate !== '' && branchId) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      fetchData(start, end);    }
  }, [filterOptions, startDate, endDate, branchId, group, rowsPerPage, page]);

  const handleChangeCheckBox = (event) => {
    const { name, checked } = event.target;
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [name]: checked,
    }));
  };

  const handlePageChange = (_, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleChangeSelectCategory = (category, values) => {
    if (category === 'sentiment') {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        selectedSentiments: values,
      }));
    }
    if (category === 'group') {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        selectedGroups: values,
      }));
    }
    if (category === 'emotion') {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        selectedEmotions: values,
      }));
    }
    if (category === 'attendant') {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        selectedAttendants: values,
      }));
    }
    if (category === 'spot') {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        selectedSpots: values,
      }));
    }
  };

  return (
    <>
      <Card sx={sx}>
        <CardHeader
          sx={{
            padding: '20px',
          }}
          title="Todas las reseñas"
          subheader="Puedes hacer filtros combinados con cada uno de los siguientes campos."
        />
        <Grid
          container
          spacing={3}
          sx={{
            paddingX: '20px',
            paddingBottom: '20px',
          }}
        >
          {bySentiment && (
            <Grid xs={12} md={6}>
              <Typography variant="subtitle2">Por sentimiento</Typography>
              <Select
                name="sentiment"
                multiple
                fullWidth
                value={filterOptions.selectedSentiments}
                onChange={(e) => {
                  handleChangeSelectCategory('sentiment', e.target.value);
                }}
              >
                {sentiments.map((sentiment, index) => (
                  <MenuItem key={index} value={sentiment}>
                    {sentiment}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {byGroup && (
            <Grid xs={12} md={6}>
              <Typography variant="subtitle2">Por grupo</Typography>
              <Select
                name="group"
                multiple
                fullWidth
                value={filterOptions.selectedGroups}
                onChange={(e) => {
                  handleChangeSelectCategory('group', e.target.value);
                }}
              >
                {groups.map((group, index) => (
                  <MenuItem key={index} value={group}>
                    {group}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {byEmotion && (
            <Grid xs={12} md={6}>
              <Typography variant="subtitle2">Por emoción</Typography>
              <Select
                name="emotion"
                multiple
                fullWidth
                value={filterOptions.selectedEmotions}
                onChange={(e) => {
                  handleChangeSelectCategory('emotion', e.target.value);
                }}
              >
                {emotions.map((emotion, index) => (
                  <MenuItem key={index} value={emotion}>
                    {emotion}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid xs={12} md={6}>
            <Typography variant="subtitle2">Por asistente</Typography>
            <Select
              name="attendant"
              multiple
              fullWidth
              value={filterOptions.selectedAttendants}
              onChange={(e) => {
                handleChangeSelectCategory('attendant', e.target.value);
              }}
            >
              {attendants.map((attendant, index) => (
                <MenuItem key={index} value={attendant.id}>
                  {attendant.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid xs={12} md={6}>
            <Typography variant="subtitle2">Por espacio</Typography>
            <Select
              name="spot"
              multiple
              fullWidth
              value={filterOptions.selectedSpots}
              onChange={(e) => {
                handleChangeSelectCategory('spot', e.target.value);
              }}
            >
              {spots.map((spot, index) => (
                <MenuItem key={index} value={spot.id}>
                  {spot.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <FormControl
          sx={{ marginX: '15px', marginY: '0px' }}
          component="fieldset"
          variant="standard"
        >
          {ordering && (
            <>
              <Typography variant="subtitle2">Ordenamiento</Typography>
              <FormGroup row={true}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterOptions.byDateAsc}
                      onChange={handleChangeCheckBox}
                      name="byDateAsc"
                    />
                  }
                  label={
                    <Typography variant="body2">Fecha Descendente</Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterOptions.byDateDesc}
                      onChange={handleChangeCheckBox}
                      name="byDateDesc"
                    />
                  }
                  label={
                    <Typography variant="body2">Fecha Ascendente</Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterOptions.byScoreAsc}
                      onChange={handleChangeCheckBox}
                      name="byScoreAsc"
                    />
                  }
                  label={
                    <Typography variant="body2">Puntaje Ascendente</Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterOptions.byScoreDesc}
                      onChange={handleChangeCheckBox}
                      name="byScoreDesc"
                    />
                  }
                  label={
                    <Typography variant="body2">Puntaje Descendente</Typography>
                  }
                />
              </FormGroup>
            </>
          )}
        </FormControl>

        <Scrollbar sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              display: 'flex',
              margin: '30px',
            }}
          >
            <SeverityPill color="success">
              <Typography variant="subtitle1">
                Positivas: {groupedBySentiment['Positivo']} (
                {`${((groupedBySentiment['Positivo'] * 100) / totalFiltered).toFixed(2)}%`}
                )
              </Typography>
            </SeverityPill>
            <SeverityPill color="warning">
              <Typography variant="subtitle1">
                Neutrales: {groupedBySentiment['Neutral']} (
                {`${((groupedBySentiment['Neutral'] * 100) / totalFiltered).toFixed(2)}%`}
                )
              </Typography>
            </SeverityPill>
            <SeverityPill color="error">
              <Typography variant="subtitle1">
                Negativas: {groupedBySentiment['Negativo']} (
                {`${((groupedBySentiment['Negativo'] * 100) / totalFiltered).toFixed(2)}%`}
                )
              </Typography>
            </SeverityPill>
          </Box>

          <Box sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection="desc">Fecha</TableCell>
                  <TableCell>Sentimiento</TableCell>
                  <TableCell>Emoción</TableCell>
                  <TableCell>Resumen</TableCell>
                  <TableCell>Grupo</TableCell>
                  <TableCell>Puntaje</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((review) => {
                  const date = format(
                    new Date(review.createdAt),
                    'dd/MM/yyyy HH:mm'
                  );
                  const dayOfTheWeek = format(
                    new Date(review.createdAt),
                    'EEEE'
                  );
                  return (
                    <TableRow hover key={review.id}>
                      <TableCell
                        onClick={() => handleOpenModal(review.rawReviewId)}
                      >
                        {dayOfWeekMapper[dayOfTheWeek]}, {date}
                      </TableCell>
                      <TableCell
                        onClick={() => handleOpenModal(review.rawReviewId)}
                      >
                        <SeverityPill color={statusMap[review.sentiment]}>
                          {review.sentiment}
                        </SeverityPill>
                      </TableCell>
                      <TableCell
                        onClick={() => handleOpenModal(review.rawReviewId)}
                      >
                        <CustomSeverityPill
                          color={
                            colors[
                              emotions.indexOf(review.emotion) % colors.length
                            ]
                          }
                        >
                          {review.emotion}
                        </CustomSeverityPill>
                      </TableCell>
                      <TableCell
                        onClick={() => handleOpenModal(review.rawReviewId)}
                      >
                        {review.summary}
                      </TableCell>
                      <TableCell
                        onClick={() => handleOpenModal(review.rawReviewId)}
                      >
                        <CustomSeverityPill
                          color={
                            colors[groups.indexOf(review.group) % colors.length]
                          }
                        >
                          {review.group}
                        </CustomSeverityPill>
                      </TableCell>
                      <TableCell
                        onClick={() => handleOpenModal(review.rawReviewId)}
                      >
                        {review.score}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={totalFiltered}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
        <Divider />
      </Card>
      <ReviewModal
        handleClose={handleCloseModal}
        open={openModal}
        parentReviewId={modalParentReviewId}
      />
    </>
  );
};


