import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  p: 4,
};

type ConfirmationModalProps={
  open: boolean;
  handleClose: ()=> void
  handleSuccess: ()=> void
  confirmationText: string
}

export default function ConfirmationModal({
  open,
  handleClose,
  handleSuccess,
  confirmationText,
}: ConfirmationModalProps) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Card>
          <CardHeader title="Confirmación" />
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
              <Typography>{confirmationText}</Typography>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={handleSuccess}>
              Confirmar
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Modal>
  );
}
