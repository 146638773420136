import { Card, CardContent, CardHeader, Stack, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import { Chart } from "../chart";
import { sentiments } from "../../utils/sentiments";
import { dayOfWeekMapper } from "../../utils/dayOfWeekMapper";
import { getReviewsByTimeApi } from "../../api/reviews";
import React from "react";

interface ReviewsWeekProps {
  sx?: object;
  startDate: string;
  endDate: string;
  branchId: number;
  group?: string;
}

interface SentimentData {
  [key: string]: number;
}

interface GroupedBySentiment {
  [key: string]: SentimentData;
}

const useChartOptions = () => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: true,
      },
    },
    colors: sentiments.colors,
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: true,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: [
        dayOfWeekMapper["Sunday"],
        dayOfWeekMapper["Monday"],
        dayOfWeekMapper["Tuesday"],
        dayOfWeekMapper["Wednesday"],
        dayOfWeekMapper["Thursday"],
        dayOfWeekMapper["Friday"],
        dayOfWeekMapper["Saturday"],
      ],
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      title: {
        text: "Número de Reseñas",
        offsetX: 5,
      },
      labels: {
        formatter: (value: number) => `${value}`,
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };
};

export const ReviewsWeek = ({
  sx,
  startDate,
  endDate,
  branchId,
  group = "",
}: ReviewsWeekProps): JSX.Element => {
  const [type, setType] = useState<"line" | "bar">("line");
  const chartOptions = useChartOptions();
  const [groupedBySentiment, setGroupedBySentiment] =
    useState<GroupedBySentiment>({});

  const chartSeries = useMemo(() => {
    const initial = sentiments.tags.map((sentiment) => ({
      name: sentiment,
      data: [] as number[],
    }));

    if (!Object.keys(groupedBySentiment).length) return initial;

    for (let i = 0; i < 7; i++) {
      sentiments.tags.forEach((sentiment) => {
        const objectToModify = initial.find((obj) => obj.name === sentiment);
        if (objectToModify) {
          const val =
            groupedBySentiment[i]?.[sentiment] !== undefined
              ? groupedBySentiment[i][sentiment]
              : 0;
          objectToModify.data.push(val);
        }
      });
    }

    return initial;
  }, [groupedBySentiment]);

  useEffect(() => {
    const fetchData = async (start: Date, end: Date) => {
      try {
        const data = group
          ? await getReviewsByTimeApi(
              branchId,
              start,
              end,
              "sentiment",
              "day",
              [],
              [group],
            )
          : await getReviewsByTimeApi(branchId, start, end, "sentiment", "day");
        setGroupedBySentiment(data as GroupedBySentiment);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    if (startDate !== "" && endDate !== "" && branchId) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      fetchData(start, end);
    }
  }, [startDate, endDate, branchId, group]);

  return (
    <Card sx={sx}>
      <CardHeader title="Reseñas por día de la semana" />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="left"
        sx={{ my: 2, ml: 2 }}
        spacing={{ xs: 1, sm: 2 }}
        useFlexGap
        flexWrap="wrap"
      >
        <Button
          variant="contained"
          onClick={() =>
            setType((prevState) => (prevState === "line" ? "bar" : "line"))
          }
        >
          {type === "line" ? "Linea" : "Barras"}
        </Button>
      </Stack>
      <CardContent>
        <Chart
          height={350}
          options={chartOptions}
          series={chartSeries}
          type={type}
          width="100%"
        />
      </CardContent>
    </Card>
  );
};
