import {
  Avatar,
  Button,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import CalendarIcon from "@heroicons/react/24/solid/CalendarIcon";
import React from "react";

interface DatesFilterProps {
  sx?: object;
  handleFilter: (startDate: string, endDate: string) => void;
}

export const DatesFilter = ({ sx, handleFilter }: DatesFilterProps) => {
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const [startDate, setStartDate] = useState<Date>(oneMonthAgo);
  const [endDate, setEndDate] = useState<Date>(new Date());

  useEffect(() => {
    handleFilter(startDate.toISOString(), endDate.toISOString());
  }, [startDate, endDate, handleFilter]);

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  const handleLastWeek = () => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    setStartDate(oneWeekAgo);
    setEndDate(new Date());
  };

  const handleLastMonth = () => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setStartDate(oneMonthAgo);
    setEndDate(new Date());
  };

  const handleLastThreeMonths = () => {
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    setStartDate(threeMonthsAgo);
    setEndDate(new Date());
  };

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              Filtro por Fechas
            </Typography>
            <Typography variant="h4">
              <Stack spacing={2} direction="row">
              {/* Adjust spacing here */}
                <DatePicker
                  label="Fecha Inicial"
                  onChange={handleStartDateChange}
                  maxDate={endDate}
                  value={startDate}
                />
                <DatePicker
                  label="Fecha Final"
                  onChange={handleEndDateChange}
                  minDate={startDate}
                  maxDate={new Date()}
                  value={endDate}
                />
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleLastWeek}
                >
                  Última semana
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleLastMonth}
                >
                  Último mes
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleLastThreeMonths}
                >
                  Últimos 3 meses
                </Button>
              </Stack>
            </Typography>
          </Stack>
          <Avatar
            sx={{ backgroundColor: "primary.main", height: 56, width: 56 }}
          >
            <SvgIcon>
              <CalendarIcon />
            </SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};
