import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Scrollbar } from '../scrollbar';
import { useEffect, useState } from 'react';
import { getAttendantsByBranchApi } from '../../api/attendants';
import { SeverityPill } from '../severity-pill';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Attendant } from '../../interfaces/attendant';

type AttendantsTableProps={
  sx?: SxProps; 
  branchId: number; 
  triggerFetch: boolean;
}

export const AttendantsTable = ({ sx, branchId, triggerFetch }: AttendantsTableProps) => {
  const navigate = useNavigate();

  const [attendants, setAttendants] = useState<Attendant[]>([]);

  const [filterOptions, setFilterOptions] = useState({
    active: true,
    inactive: false,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let statusList: boolean[]= [];
        if (filterOptions.active) statusList.push(true);
        if (filterOptions.inactive) statusList.push(false);
        const data: Attendant[] = await getAttendantsByBranchApi(branchId, statusList);
        setAttendants(data);
      } catch (error) {
        console.error('Error fetching attendants:', error);
      }
    };
    branchId && fetchData();
  }, [branchId, triggerFetch, filterOptions]);

  return (
    <Card sx={sx}>
      <CardHeader title="Asistentes registrados" sx={{ padding: '15px' }} />
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterOptions.active}
                onChange={handleChange}
                name="active"
              />
            }
            label="Activo"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterOptions.inactive}
                onChange={handleChange}
                name="inactive"
              />
            }
            label="Inactivo"
          />
        </FormGroup>
      </FormControl>

      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection="desc">Nombre</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attendants.map((attendant) => {
                return (
                  <TableRow hover key={attendant.id}>
                    <TableCell
                      onClick={() =>
                        navigate(`/adminPanel/attendant/${attendant.id}`)
                      }
                    >
                      {attendant.name}
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        navigate(`/adminPanel/attendant/${attendant.id}`)
                      }
                    >
                      <SeverityPill
                        color={attendant.status ? 'success' : 'error'}
                      >
                        {attendant.status ? 'Activo' : 'Inactivo'}
                      </SeverityPill>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Divider />
    </Card>
  );
};

