import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { NewBranchForm } from '../forms/new-branch-form';
import React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  p: 4,
};

interface BranchModalProps {
  open: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  businessId: number;
}


export default function BranchModal({
  open,
  handleClose,
  handleSuccess,
  businessId,
}: BranchModalProps) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <NewBranchForm
            handleSuccess={handleSuccess}
            businessId={businessId}
          />
        </Box>
      </Modal>
    </div>
  );
}
