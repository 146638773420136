import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { postReviewApi } from '../api/reviews';
import { ToastContainer, toast } from 'react-toastify';
import { ToastError, ToastSuccess } from '../components/shared/Toast';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBranchPublicInfoApi } from '../api/branch';
import { BranchPublicInfo } from '../interfaces/branch.public.info';
import React from 'react';
import { Attendant } from '../interfaces/attendant';
import { Spot } from '../interfaces/spot';

const NewReview = () => {
  const { branchId } = useParams();
  const [loading, setLoading] = useState(false);
  const [attendants, setAttendants] = useState<Attendant[]>([]);
  const [spots, setSpots] = useState<Spot[]>([]);

  const lastCalledRef = useRef(0);

  const [enforceAttendants, setEnforceAttendants] = useState(false);
  const [enforceSpots, setEnforceSpots] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: BranchPublicInfo = await getBranchPublicInfoApi(+branchId!);
        setAttendants(data.attendants);
        setSpots(data.spots);
        setEnforceAttendants(data.enforceAttendants);
        setEnforceSpots(data.enforceSpots);
      } catch (error) {
        console.error("Error fetching branche's public info:", error);
      }
    };
    const now = Date.now();
    if (branchId && now - lastCalledRef.current > 5000) {
      lastCalledRef.current = now;
      fetchData();
    }
  }, [branchId]);

  const formik = useFormik({
    initialValues: {
      review: '' as string,
      attendantId: undefined as number|undefined,
      spotId: undefined as number|undefined,
      submit: null,
    },
    validationSchema: Yup.object({
      review: Yup.string().max(255).required('Inserta Reseña'),
      attendantId: enforceAttendants
        ? Yup.number()
            .nullable()
            .positive()
            .integer()
            .required('Debes seleccionar el asistente')
        : Yup.number().nullable().positive().integer(),
      spotId: enforceSpots
        ? Yup.number()
            .nullable()
            .positive()
            .integer()
            .required('Debes seleccionar el espacio')
        : Yup.number().nullable().positive().integer(),
    }),
    onSubmit: async (values, helpers) => {
      const id = toast.loading('Cargando...');
      setLoading(true);
      try {
        await postReviewApi(values, +branchId!);
        ToastSuccess(id, 'Reseña Procesada');
        helpers.resetForm();
      } catch (err) {
        ToastError(id, 'Error al procesar la reseña');
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
        if (err.response && err.response.data && err.response.data.message) {
          if (
            err.response.data.message === 'Attendant assignment is enforced'
          ) {
            helpers.setErrors({ submit: 'Debes seleccionar el asistente' });
          } else if (
            err.response.data.message === 'Spot assignment is enforced'
          ) {
            helpers.setErrors({ submit: 'Debes seleccionar el espacio' });
          } else {
            helpers.setErrors({ submit: err.response.data.message });
          }
        } else {
          helpers.setErrors({ submit: err.message });
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <ToastContainer />
      <Box
        sx={{
          backgroundColor: 'background.paper',
          flex: '1 1 auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: '100px',
            width: '100%',
          }}
        >
          <div>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography variant="h4">Subir Reseña</Typography>
              <Typography variant="h6">
                Coméntanos sobre tu experiencia, tu opinión es importante
              </Typography>
            </Stack>
            <form noValidate onSubmit={formik.handleSubmit}>
              {(enforceAttendants || enforceSpots) && (
                <Box
                  sx={{
                    padding: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    display: 'flex',
                  }}
                >
                  {enforceAttendants && (
                    <Stack>
                      <InputLabel>Asistente</InputLabel>
                      <Select
                        name="attendantId"
                        error={
                          !!(
                            formik.touched.attendantId &&
                            formik.errors.attendantId
                          )
                        }
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.attendantId}
                      >
                        {attendants.map((attendant) => (
                          <MenuItem key={attendant.id} value={attendant.id}>
                            {attendant.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  )}
                  {enforceSpots && (
                    <Stack>
                      <InputLabel>Espacio</InputLabel>
                      <Select
                        name="spotId"
                        error={
                          !!(formik.touched.spotId && formik.errors.spotId)
                        }
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.spotId}
                      >
                        {spots.map((spot) => (
                          <MenuItem key={spot.id} value={spot.id}>
                            {spot.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  )}
                </Box>
              )}
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.review && formik.errors.review)}
                  fullWidth
                  helperText={formik.touched.review && formik.errors.review}
                  label="Reseña"
                  name="review"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.review}
                  multiline
                  minRows={3}
                />
              </Stack>
              {formik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {formik.errors.submit}
                </Typography>
              )}
              <Button
                fullWidth
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
                disabled={loading}
              >
                Subir
              </Button>
            </form>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default NewReview;
