import {
  Box,
  Divider,
  Drawer,
  Stack,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { Scrollbar } from "../../components/scrollbar";
import { items } from "./config";
import { SideNavItem } from "./side-nav-item";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../hooks/useAuthStore";
import { User } from "../../interfaces/user";
import { Business } from "../../interfaces/business";
import React from "react";

export const SideNav = (props) => {
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { user, business } = useAuthStore() as {
    user: User;
    business: Business;
  };

  const content = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
        },
        "& .simplebar-scrollbar:before": {
          background: "neutral.400",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box
            // component={NextLink}
            //  href="/"
            onClick={() => navigate("/")}
            sx={{
              display: "inline-flex",
              height: 32,
              width: 32,
            }}
          >
            <img
              src="/tdd_logo_blanco.png"
              width={197}
              height={51}
              alt="Logo"
            />
          </Box>
        </Box>
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3,
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: "none",
              p: 0,
              m: 0,
            }}
          >
            {items
              .filter((item) => {
                if (user.role === "OWNER") {
                  return true;
                }
                if (user.role === "MANAGER") {
                  return item.role === user.role;
                }
                return false;
              })
              .map((item) => {
                const active = item.path ? pathname === item.path : false;

                // If paid, hide pricing
                if (
                  business.stripeStatus === "paid" &&
                  item.path === "/pricing"
                ) {
                  return null;
                }

                // If not paid, hide everything except pricing
                if (
                  (business.stripeStatus !== "paid" ||
                    !business.stripeStatus) &&
                  item.path !== "/pricing"
                ) {
                  return null;
                }

                return (
                  <SideNavItem
                    active={active}
                    disabled={item.disabled}
                    external={item.external}
                    icon={item.icon}
                    key={item.title}
                    path={item.path}
                    title={item.title}
                  />
                );
              })}
          </Stack>
        </Box>
        <Divider sx={{ borderColor: "neutral.700" }} />
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.800",
            color: "common.white",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.800",
          color: "common.white",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};


