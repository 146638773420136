import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Typography,
} from "@mui/material";
import { Layout as DashboardLayout } from "../layouts/dashboard/layout";
import { AllReviewsTable } from "../components/tables/all-reviews";
import { TotalReviews } from "../components/cards/total-reviews";
import { DatesFilter } from "../components/cards/dateFilter";
import { useEffect, useRef, useState } from "react";
import { getReviewsApi } from "../api/reviews";
import { TopReviewsTable } from "../components/tables/top-reviews";
import { useAuthStore } from "../hooks/useAuthStore";
import React from "react";
import { Branch } from "../interfaces/branch";
import { ReviewResponseEntity } from "../interfaces/review/review-response";

const Tables: React.FC = () => {
  const [totalReviews, setTotalReviews] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
    const lastCalledRef = useRef(0);


  const { currentBranch } = useAuthStore() as { currentBranch: Branch };

  const handleDateChange = (start: string, end: string): void => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    const fetchData = async (start: Date, end: Date) => {
      try {
        const data: ReviewResponseEntity = await getReviewsApi(currentBranch.id, start, end);
        setTotalReviews(data.reviewsPage.totalReviews);
      } catch (error) {
        console.error("Error fetching account data:", error);
      }
    };

 const now = Date.now();
    if (
      startDate !== '' &&
      endDate !== '' &&
      currentBranch.id &&
      now - lastCalledRef.current > 5000
    ) {
      lastCalledRef.current = now;      const start = new Date(startDate);
      const end = new Date(endDate);
      fetchData(start, end);
    }
  }, [startDate, endDate, currentBranch]);

  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h1">{currentBranch.name}</Typography>
          <Grid container spacing={3}>
            <Grid xs={12} lg={9}>
              <DatesFilter
                handleFilter={handleDateChange}
                sx={{ height: "100%" }}
              />
            </Grid>
            <Grid xs={3}>
              <TotalReviews
                sx={{ height: "100%" }}
                reviewsTotal={totalReviews}
              />
            </Grid>
            <Grid xs={12}>
              <TopReviewsTable
                type="positive"
                startDate={startDate}
                endDate={endDate}
                branchId={currentBranch.id}
                sx={{ height: "100%" }}
              />
            </Grid>
            <Grid xs={12}>
              <TopReviewsTable
                type="negative"
                startDate={startDate}
                endDate={endDate}
                branchId={currentBranch.id}
                sx={{ height: "100%" }}
              />
            </Grid>
            <Grid xs={12}>
              <AllReviewsTable
                startDate={startDate}
                endDate={endDate}
                branchId={currentBranch.id}
                sx={{ height: "100%" }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Tables;
