import cookies from "js-cookie";
import jwtDecode from "jwt-decode";

const ACCESS_TOKEN = "accessToken";

export function setToken(token: string): void {
  cookies.set(`${ACCESS_TOKEN}`, token);
}

export function getToken(): Promise<string | null> {
  const token = cookies.get(ACCESS_TOKEN);
  if (!token || token === "null") return Promise.resolve(null);
  return Promise.resolve(tokenExpire(token) ? null : token);
}

export function removeToken(): void {
  cookies.remove(ACCESS_TOKEN);
}

export function tokenExpire(token: string): boolean {
  const seconds = 60;
  const metatoken = jwtDecode(token) as { exp: number };
  const { exp } = metatoken;
  const now = (Date.now() + seconds) / 1000;
  return now > exp;
}
