import axios from 'axios';
import { getToken } from '../utils/token';
import { Spot, SpotI } from '../interfaces/spot';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getSpotsByBranchApi(id: number, status: boolean[] = []): Promise<Spot[]> {
  let url = `${envUrl}/spots/branch/${id}/`;
  if (status.length) {
    url += `?status=${JSON.stringify(status)}`;
  }

  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .get(url, config)
    .then((response) => response.data.map((spot: SpotI) => new Spot(spot)))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function getSpotByIdApi(id: number): Promise<Spot> {
  const url = `${envUrl}/spots/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .get(url, config)
    .then((response) => new Spot(response.data as SpotI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function postSpotApi(data: Omit<SpotI, "id" | "createdAt" | "updatedAt">): Promise<Spot>{
  const url = `${envUrl}/spots`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .post(url, data, config)
    .then((response) => new Spot(response.data as SpotI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function patchSpotApi(id:number, data: Partial<Spot>): Promise<Spot>{
  const url = `${envUrl}/spots/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .patch(url, data, config)
    .then((response) => new Spot(response.data as SpotI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function deleteSpotApi(id: number): Promise<Spot>{
  const url = `${envUrl}/spots/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .delete(url, config)
    .then((response) => new Spot(response.data as SpotI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}
