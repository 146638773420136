export interface SpotI {
    id: number;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    status: boolean;
    branchId: number;
  }
  
  export class Spot implements SpotI {
    id: number;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    status: boolean;
    branchId: number;
  
    constructor(spotI: Partial<SpotI> = {}) {
      this.id = spotI.id ?? 0; 
      this.createdAt = spotI.createdAt ?? new Date();
      this.updatedAt = spotI.updatedAt ?? new Date();
      this.name = spotI.name ?? '';
      this.status = spotI.status ?? true;
      this.branchId = spotI.branchId ?? 0;
    }
  }
  