import { useDispatch, useSelector } from 'react-redux';
import {
  onAddBranches,
  onAddBusiness,
  onChangeCurrentBranch,
  onChecking,
  onLogin,
  onLogout,
} from '../store/auth/authSlice';
import { getToken, removeToken, setToken } from '../utils/token';
import { getBranchesByBusinessApi, getUserBranchesApi } from '../api/branch';
import { loginApi, signUpApi, userByTokenApi } from '../api/auth';
import { getBusinessByUserApi } from '../api/businesses';
import { AuthState } from '../interfaces/authstate';
import { User } from '../interfaces/user';
import { Business } from '../interfaces/business';
import { Branch } from '../interfaces/branch';

interface RootState {
  auth: AuthState;
}

export const useAuthStore = () => {
  const {
    branches,
    business,
    currentBranch,
    isAuthenticated,
    isLoading,
    user,
  } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const startSignUp = async ({
    username,
    email,
    password,
    businessName,
    businessDescription,
    branchName,
    branchAddress,
    branchGroups,
  }: {
    username: string;
    email: string;
    password: string;
    businessName: string;
    businessDescription: string;
    branchName: string;
    branchAddress: string;
    branchGroups: string;
  }) => {
    dispatch(onChecking());

    try {
      const response = await signUpApi(
        username,
        email,
        password,
        businessName,
        businessDescription,
        branchName,
        branchAddress,
        branchGroups
      );
      const token = response.accessToken;
      setToken(token);
    } catch (error) {
      throw new Error('Invalid Credentials');
    }

    try {
      const user:User = await userByTokenApi();
      if (user.role === 'OWNER') {
        const business: Business = await getBusinessByUserApi();
        const branches: Branch[]= await getBranchesByBusinessApi(business.id);
        dispatch(onAddBranches([...branches]));
        dispatch(onAddBusiness({ ...business }));
        dispatch(onChangeCurrentBranch({ ...branches[0] }));
      }
      if (user.role === 'MANAGER') {
        const branches: Branch[] = await getUserBranchesApi();
        const business: Business = branches[0]?.business!;
        dispatch(onAddBranches([...branches]));
        dispatch(onAddBusiness({ ...business }));
        dispatch(onChangeCurrentBranch({ ...branches[0] }));
      }
      if (user.role === 'ADMIN') {
        throw new Error('Error setting user');
      }
      dispatch(onLogin({ ...user }));
    } catch (error) {
      dispatch(onLogout());
      throw new Error('Error setting user');
    }
  };

  const startLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {    dispatch(onChecking());

    try {
      const response = await loginApi(email, password);
      const token = response.accessToken;
      setToken(token);
    } catch (error) {
      throw new Error('Invalid Credentials');
    }

    try {
      const user: User = await userByTokenApi();
      if (user.role === 'OWNER') {
        const business: Business = await getBusinessByUserApi();
        const branches: Branch[] = await getBranchesByBusinessApi(business.id);
        dispatch(onAddBranches([...branches]));
        dispatch(onAddBusiness({ ...business }));
        dispatch(onChangeCurrentBranch({ ...branches[0] }));
      }
      if (user.role === 'MANAGER') {
        const branches: Branch[]= await getUserBranchesApi();
        const business: Business = branches[0]?.business!;
        dispatch(onAddBranches([...branches]));
        dispatch(onAddBusiness({ ...business }));
        dispatch(onChangeCurrentBranch({ ...branches[0] }));
      }
      dispatch(onLogin({ ...user }));
    } catch (error) {
      dispatch(onLogout());
      throw new Error('Error setting user');
    }
  };

  const startLogout = () => {
    removeToken();
    dispatch(onLogout());
  };

  const checkAuthToken = async () => {
    const token = getToken();
    if (!token) return dispatch(onLogout());

    try {
      const user: User = await userByTokenApi();
      if (user.role === 'OWNER') {
        const business: Business = await getBusinessByUserApi();
        const branches: Branch[] = await getBranchesByBusinessApi(business.id);
        dispatch(onAddBranches([...branches]));
        dispatch(onAddBusiness({ ...business }));
        dispatch(onChangeCurrentBranch({ ...branches[0] }));
      }
      if (user.role === 'MANAGER') {
        const branches: Branch[] = await getUserBranchesApi();
        const business: Business = branches[0]?.business!;
        dispatch(onAddBranches([...branches]));
        dispatch(onAddBusiness({ ...business }));
        dispatch(onChangeCurrentBranch({ ...branches[0] }));
      }
      dispatch(onLogin({ ...user }));
    } catch (error) {
      dispatch(onLogout());
      throw new Error('Error setting user');
    }
  };

  const updateCurrentUser = (user: User) => {
    dispatch(onLogin({ ...user }));
  };

  const updateCurrentBranch = (branch: Branch) => {
    dispatch(onChangeCurrentBranch({ ...branch }));
  };

  const updateBranches = (branches: Branch[]) => {
    dispatch(onAddBranches([...branches]));
  };

  const updateBusiness = (business: Business) => {
    dispatch(onAddBusiness({ ...business }));
  };

  return {
    // props
    branches,
    business,
    currentBranch,
    isAuthenticated,
    isLoading,
    user,
    // methods
    checkAuthToken,
    startLogin,
    startLogout,
    startSignUp,
    updateCurrentBranch,
    updateBranches,
    updateBusiness,
    updateCurrentUser,
  };
};
