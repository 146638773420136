import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Typography,
} from "@mui/material";
import { Layout as DashboardLayout } from "../layouts/dashboard/layout";
import { useEffect, useRef, useState } from "react";
import { getReportsApi } from "../api/reports";
import { AIReport } from "../components/cards/ai-report";
import { useAuthStore } from "../hooks/useAuthStore";
import React from "react";
import { Branch } from "../interfaces/branch";
import { Report } from "../interfaces/report";

const Reports = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const { currentBranch } = useAuthStore() as { currentBranch: Branch };
    const lastCalledRef = useRef(0);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: Report[] = await getReportsApi(currentBranch.id);
        setReports(data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
  const now = Date.now();
    if (currentBranch.id && now - lastCalledRef.current > 5000) {
      lastCalledRef.current = now;
      fetchData();
    }  }, [currentBranch]);

  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h1">{currentBranch.name}</Typography>
          <Grid container spacing={3}>
            <Grid xs={12}>
              {reports.map((report) => (
                <AIReport
                  key={report.id}
                  inputReport={report}
                  latestReport={false}
                />
              ))}
              {!reports.length && (
                <>No hay un reporte generado hasta el momento.</>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Reports;
