import { Layout as DashboardLayout } from '../../layouts/dashboard/layout';
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { NewBranchForm } from '../../components/forms/new-branch-form';
import { useEffect, useRef, useState } from 'react';
import { getBranchApi } from '../../api/branch';
import { ToastContainer } from 'react-toastify';
import { ToastSuccessSync } from '../../components/shared/Toast';
import { BranchUserForm } from '../../components/forms/branch-user-form';
import { useAuthStore } from '../../hooks/useAuthStore';
import { LllmGroupsForm } from '../../components/forms/llm-groups-form';
import { useParams } from 'react-router-dom';
import { AttendantsSpotsEnforcementForm } from '../../components/forms/attendants-spots-enforcement-form';
import { AttendantsTable } from '../../components/tables/attendants-table';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { SpotsTable } from '../../components/tables/spots-table';
import AttendantOrSpotModal from '../../components/modals/attendantOrSpotModal';
import { OwnerConditionalRendering } from '../../guards/owner-conditional-rendering';
import React from 'react';
import { Branch as BranchEntity} from '../../interfaces/branch';
import { Business } from '../../interfaces/business';

const Branch = () => {
  const [branch, setBranch] = useState<BranchEntity | undefined>(undefined);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const { branchId } = useParams();
  const { business, updateCurrentBranch } = useAuthStore() as {business: Business, updateCurrentBranch:(BranchEntity)=> void};
  const lastCalledRef = useRef(0);

  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<string>('');
  const handleOpenModal = (type) => {
    setModalType(type);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const handleSuccess = () => {
    handleCloseModal();
    ToastSuccessSync('Procedimiento Exitoso');
    setTriggerFetch((prevState) => !prevState);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: BranchEntity = await getBranchApi(+branchId!);
        setBranch(data);
        updateCurrentBranch(data);
      } catch (error) {
        console.error('Error fetching branch:', error);
      }
    };
    const now = Date.now();
    if (branchId && (now - lastCalledRef.current > 5000 || triggerFetch)) {
      lastCalledRef.current = now;
      fetchData();
    }
    // eslint-disable-next-line
  }, [branchId, triggerFetch]);

  return (
    // <RoleGuard allowedRoles={['OWNER']}>
    <DashboardLayout>
      <ToastContainer />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">{branch?.name}</Typography>
              </Stack>
            </Stack>
            <NewBranchForm
              newBranch={false}
              businessId={business.id}
              branch={branch}
              handleSuccess={handleSuccess}
            />
            <OwnerConditionalRendering>
              <BranchUserForm branch={branch!} handleSuccess={handleSuccess} />
            </OwnerConditionalRendering>
            <OwnerConditionalRendering>
              <AttendantsSpotsEnforcementForm handleSuccess={handleSuccess} />
            </OwnerConditionalRendering>
            <OwnerConditionalRendering>
              <LllmGroupsForm branch={branch!} handleSuccess={handleSuccess} />
            </OwnerConditionalRendering>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Asistentes</Typography>
              </Stack>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
                onClick={() => handleOpenModal('attendant')}
              >
                Agregar Nuevos Asistentes
              </Button>
            </Stack>
            <AttendantsTable branchId={branch?.id || 0} triggerFetch={triggerFetch} />
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Espacios</Typography>
              </Stack>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
                onClick={() => handleOpenModal('spot')}
              >
                Agregar Nuevos Espacios
              </Button>
            </Stack>
            <SpotsTable branchId={branch?.id || 0} triggerFetch={triggerFetch} />
            </Stack>
        </Container>
      </Box>
      <AttendantOrSpotModal
        modalType={modalType}
        open={openModal}
        branchId={branch?.id || 0}
        handleClose={handleCloseModal}
        handleSuccess={handleSuccess}
      />
    </DashboardLayout>
    // </RoleGuard>
  );
};

export default Branch;
