// src/interfaces/review-response.interface.ts

import { ReviewPaginationEntity, ReviewPaginationEntityI } from './review-pagination';

export interface ReviewResponseEntityI {
  reviewsPage: ReviewPaginationEntityI;
  groupByEmotion: object;
  groupByGroup: object;
  groupBySentiment: object;
  groupByMonth: object;
  groupByDayOfWeek: object;
  groupByDate: object;
  groupByHour: object;
}


export class ReviewResponseEntity implements ReviewResponseEntityI {
    reviewsPage: ReviewPaginationEntityI;
    groupByEmotion: object;
    groupByGroup: object;
    groupBySentiment: object;
    groupByMonth: object;
    groupByDayOfWeek: object;
    groupByDate: object;
    groupByHour: object;

  constructor(response: ReviewResponseEntityI) {
    this.reviewsPage = new ReviewPaginationEntity(response.reviewsPage as ReviewPaginationEntityI);
    this.groupByEmotion = response.groupByEmotion;
    this.groupByGroup = response.groupByGroup;
    this.groupBySentiment = response.groupBySentiment;
    this.groupByMonth = response.groupByMonth;
    this.groupByDayOfWeek = response.groupByDayOfWeek;
    this.groupByDate = response.groupByDate;
    this.groupByHour = response.groupByHour;
  }
}
