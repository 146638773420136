import { format } from "date-fns";
import BoltIcon from "@heroicons/react/24/outline/BoltIcon";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  SxProps,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getLatestReportApi } from "../../api/reports";
import { dayOfWeekMapper } from "../../utils/dayOfWeekMapper";
import ReportModal from '../modals/reportModal';
import { useAuthStore } from "../../hooks/useAuthStore";
import FireIcon from "@heroicons/react/24/solid/FireIcon";
import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import { Report } from "../../interfaces/report";
import { Theme } from "@emotion/react";
import React from "react";
import { Branch } from "../../interfaces/branch";

interface AIReportProps {
  sx?: SxProps<Theme>;
  latestReport?: boolean;
  inputReport?: Report;
}

const defaultInputReport = {
  startDate: undefined,
  finishDate: undefined,
  requestedAt: undefined,
  strengths: undefined,
  weaknesses: undefined,
  conclusion: undefined,
  user: undefined,
};

export const AIReport: React.FC<AIReportProps> = ({
  sx,
  latestReport = true,
  inputReport = defaultInputReport,
}): React.ReactElement => {
  const { currentBranch } = useAuthStore() as { currentBranch: Branch };

  const [report, setReport] = useState<Partial<Report>>(defaultInputReport);
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [requestedAt, setRequestedAt] = useState("");
  const [triggerReload, setTriggerReload] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSuccess = () => {
    handleCloseModal();
    setTriggerReload(!triggerReload);
  };

  useEffect(() => {
    if (report && report.startDate && report.finishDate && report.requestedAt) {
      const startDate = format(new Date(report.startDate), "dd/MM/yyyy");
      const startDateDay = format(new Date(report.startDate), "EEEE");
      setStartDate(`${dayOfWeekMapper[startDateDay]} ${startDate}`);

      const finishDate = format(new Date(report.finishDate), "dd/MM/yyyy");
      const finishDateDay = format(new Date(report.finishDate), "EEEE");
      setFinishDate(`${dayOfWeekMapper[finishDateDay]} ${finishDate}`);

      const requestedAt = format(new Date(report.requestedAt), "dd/MM/yyyy");
      const requestedAtDay = format(new Date(report.requestedAt), "EEEE");
      setRequestedAt(`${dayOfWeekMapper[requestedAtDay]} ${requestedAt}`);
    }
  }, [report]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLatestReportApi(currentBranch.id);
        setReport(data);
      } catch (error) {
        console.error("Error fetching latest report:", error);
      }
    };

    if (latestReport && currentBranch.id) {
      fetchData();
    } else {
      setReport(inputReport);
    }
  }, [triggerReload, currentBranch, inputReport, latestReport]);

  return (
    <React.Fragment>
      <Card
        sx={{
          paddingX: "10px",
          paddingTop: "10px",
          ...sx,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{ margin: "10px" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ margin: "10px" }}
          >
            {latestReport ? (
              <>
                <Typography variant="h6">
                  Último Reporte Mediante Inteligencia Artificial
                </Typography>
                <SvgIcon fontSize="small">
                  <BoltIcon />
                </SvgIcon>
              </>
            ) : (
              <Stack>
                <Typography variant="subtitle1">
                  Análisis desde {startDate} hasta {finishDate}
                </Typography>
              </Stack>
            )}
          </Stack>
          {report?.conclusion && (
            <Stack
              alignItems="end"
              justifyContent="right"
              sx={{ margin: "10px" }}
            >
              <Stack>
                <Typography variant="overline">
                  Análisis hecho el {requestedAt}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        <CardContent sx={{ padding: "10px" }}>
          {report?.strengths && report?.weaknesses && (
            <Stack spacing={3} margin={1}>
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  display: "flex",
                }}
              >
                <Card
                  sx={{
                    maxWidth: "40%",
                    backgroundColor: "#3499dc",
                    padding: "2px",
                  }}
                >
                  <CardContent>
                    <Stack>
                      <Box
                        sx={{
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          display: "flex",
                        }}
                      >
                        <SvgIcon>
                          <FireIcon />
                        </SvgIcon>
                        <Typography variant="overline">Fortalezas</Typography>
                        <SvgIcon>
                          <FireIcon />
                        </SvgIcon>
                      </Box>
                      <Typography variant="body1">
                        {report.strengths}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    maxWidth: "40%",
                    backgroundColor: "#fdad01",
                    padding: "2px",
                  }}
                >
                  <CardContent>
                    <Stack>
                      <Box
                        sx={{
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          display: "flex",
                        }}
                      >
                        <SvgIcon>
                          <ExclamationTriangleIcon />
                        </SvgIcon>
                        <Typography variant="overline">Debilidades</Typography>
                        <SvgIcon>
                          <ExclamationTriangleIcon />
                        </SvgIcon>
                      </Box>
                      <Typography variant="body1">
                        {report.weaknesses}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Box>
            </Stack>
          )}
          <Typography variant="body1">
            {report?.conclusion ? (
              <>{report.conclusion}</>
            ) : (
              <>No hay un reporte generado hasta el momento.</>
            )}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={{ margin: "10px" }}
          >
            {report?.conclusion && (
              <Stack
                alignItems="start"
                justifyContent="right"
                // spacing={1}
                sx={{ margin: "10px" }}
              >
                {latestReport ? (
                  <Stack>
                    <Typography variant="overline">
                      Análisis desde {startDate} hasta {finishDate}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack>
                    {report?.user && (
                      <Typography variant="overline">
                        Análisis hecho por {report.user.name} (
                        {report.user.email})
                      </Typography>
                    )}
                  </Stack>
                )}
              </Stack>
            )}

            {latestReport && (
              <Stack
                direction="row"
                justifyContent="right"
                spacing={3}
                sx={{ marginTop: "10px" }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleOpenModal}
                >
                  Generar Análisis
                </Button>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>

      <ReportModal
        open={openModal}
        handleClose={handleCloseModal}
        handleSuccess={handleSuccess}
      />
    </React.Fragment>
  );
};
