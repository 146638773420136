import { Business, BusinessI } from "./business";

export interface BranchI {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  address: string;
  groups: string;
  status: boolean;
  businessId: number;
  business?: BusinessI;
}

export class Branch implements BranchI {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  address: string;
  groups: string;
  status: boolean;
  businessId: number;
  business?: Business;

  constructor(branchI: Partial<BranchI> = {}) {
    this.id = branchI.id ?? 0;
    this.createdAt = branchI.createdAt ?? new Date();
    this.updatedAt = branchI.updatedAt ?? new Date();
    this.name = branchI.name ?? '';
    this.address = branchI.address ?? '';
    this.groups = branchI.groups ?? '';
    this.status = branchI.status ?? false;
    this.businessId = branchI.businessId ?? 0;
    this.business = branchI.business ? new Business(branchI.business) : undefined;
  }
}
