import { useEffect } from "react";
import { useAuthStore } from "../hooks/useAuthStore";
import { useNavigate } from "react-router-dom";
import { Branch } from "../interfaces/branch";

const BranchHydration = () => {
  const { currentBranch } = useAuthStore() as { currentBranch: Branch };
  const navigate = useNavigate();

  useEffect(() => {
    if (currentBranch?.id) {
      window.open(`newReview/${currentBranch.id}`, "_blank");
      navigate("/");
    }
  }, [currentBranch, navigate]);

  return null;
};

export default BranchHydration;
