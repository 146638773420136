import { toast, Id } from "react-toastify";

export const ToastSuccess = (id: Id, message: string): void => {
  toast.update(id, {
    render: `${message}`,
    type: "success",
    autoClose: 2000,
    hideProgressBar: true,
    closeButton: true,
    closeOnClick: true,
    isLoading: false,
  });
};

export const ToastSuccessSync = (message: string): void => {
  toast(message, {
    type: "success",
    autoClose: 2000,
    hideProgressBar: true,
    closeButton: true,
    closeOnClick: true,
    isLoading: false,
  });
};

export const ToastError = (id: Id, message: string): void => {
  toast.update(id, {
    render: `${message}`,
    type: "error",
    autoClose: 2000,
    hideProgressBar: true,
    closeButton: true,
    closeOnClick: true,
    isLoading: false,
  });
};
