import axios from 'axios';
import { getToken } from '../utils/token';
import { Business, BusinessI } from '../interfaces/business';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getBusinessByUserApi(): Promise<Business> {
  const url = `${envUrl}/businesses/user`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => {
      return new Business(response.data as BusinessI);
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}


export async function getBusinessApi(id: number):Promise<Business> {
  const url = `${envUrl}/businesses/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .get(url, config)
    .then((response) => {
      return new Business(response.data as BusinessI);
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function patchBusinessApi(id: number, data: Partial<BusinessI>): Promise<Business> {
  const url = `${envUrl}/businesses/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .patch(url, data, config)
    .then((response) => {
      return new Business(response.data as BusinessI);
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}
