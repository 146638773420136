import { User, UserI } from "./user";

export interface ReportI {
  id: number;
  conclusion: string;
  strengths?: string; 
  weaknesses?: string; 
  requestedAt: Date;
  startDate: Date;
  finishDate: Date;
  branchId: number;
  userId: number;
  user?: UserI;
}

export class Report implements ReportI {
  id: number;
  conclusion: string;
  strengths?: string;
  weaknesses?: string;
  requestedAt: Date;
  startDate: Date;
  finishDate: Date;
  branchId: number;
  userId: number;
  user?: User;

  constructor(report: Partial<ReportI>) {
    this.id = report.id ?? 0;
    this.conclusion = report.conclusion ?? '';
    this.strengths = report.strengths;
    this.weaknesses = report.weaknesses;
    this.requestedAt = report.requestedAt ?? new Date();
    this.startDate = report.startDate ?? new Date();
    this.finishDate = report.finishDate ?? new Date();
    this.branchId = report.branchId ?? 0;
    this.userId = report.userId ?? 0;
    this.user = report.user ? new User(report.user as UserI) : undefined;
  }
}
