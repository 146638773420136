import { format } from "date-fns";
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Scrollbar } from "../scrollbar";
import { SeverityPill } from "../severity-pill";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";

type BranchesTableProps = {
  count: number;
  items: any[];
  onPageChange: any;
  onRowsPerPageChange: any;
  page: number;
  rowsPerPage: number;
  handleFilters: (filters) => void;
};

export const BranchesTable: React.FC<BranchesTableProps> = (props) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    handleFilters = () => {},
  } = props;

  const navigate = useNavigate();

  const [filterOptions, setFilterOptions] = useState({
    active: false,
    inactive: false,
  });

  const { active, inactive } = filterOptions;

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [name]: checked,
    }));
  };

  useEffect(() => {
    handleFilters(filterOptions);
    // eslint-disable-next-line
  }, [filterOptions]);

  return (
    <Card>
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Checkbox
                checked={active}
                onChange={handleChange}
                name="active"
              />
            }
            label="Activo"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={inactive}
                onChange={handleChange}
                name="inactive"
              />
            }
            label="Inactivo"
          />
        </FormGroup>
      </FormControl>

      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Dirección</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Creada</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((branch) => {
                const createdAt = format(
                  new Date(branch.createdAt),
                  "dd/MM/yyyy",
                );
                return (
                  <TableRow hover key={branch.name}>
                    <TableCell
                      onClick={() =>
                        navigate(`/adminPanel/branches/${branch.id}`)
                      }
                    >
                      {branch.name}
                    </TableCell>
                    <TableCell>{branch.address}</TableCell>
                    <TableCell>
                      <SeverityPill color={branch.status ? "success" : "error"}>
                        {branch.status ? "Activo" : "Inactivo"}
                      </SeverityPill>
                    </TableCell>
                    <TableCell>{createdAt}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};
