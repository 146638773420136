import { Layout as DashboardLayout } from '../layouts/dashboard/layout';
import { Box, Button, Stack } from '@mui/material';
import React from 'react';

function CurrentPlan() {
  return (
    <DashboardLayout>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          flex: '1 1 auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: '100px',
            width: '100%',
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
            }}
          >
            <p>
              Accede a tu plan mediante la siguiente liga. Recuerda utilizar el
              correo electrónico con el que adquiriste tu suscripción.
            </p>
            <Button
              onClick={() => {
                const url =
                  'https://billing.stripe.com/p/login/00gaFX7bbbVsbbW9AA/';
                window.open(url, '_blank');
              }}
            >
              Cuenta Stripe
            </Button>
          </Stack>
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default CurrentPlan;
