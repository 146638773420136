import axios from 'axios';
import { resetTimeToMidnight } from '../utils/dates';
import { getToken } from '../utils/token';
import { Report, ReportI } from '../interfaces/report';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getReportsApi(branchId: number): Promise<Report[]> {
  const url = `${envUrl}/reports/branch/${branchId}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => response.data.map((report: ReportI) => new Report(report)))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function getLatestReportApi(branchId: number): Promise<Report> {
  const url = `${envUrl}/reports/branch/latest/${branchId}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => new Report(response.data as ReportI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function getReportByIdApi(reportId: number): Promise<Report> {
  const url = `${envUrl}/reports/${reportId}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => new Report(response.data as ReportI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function postReportApi(branchId: number, start: Date, end: Date): Promise<Report> {
  const url = `${envUrl}/reports/branch/${branchId}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .post(
      url,
      {
        startDate: resetTimeToMidnight(start),
        finishDate: resetTimeToMidnight(end),
      },
      config
    )
    .then((response) => new Report(response.data as ReportI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}
