import axios from 'axios';
import { getToken } from '../utils/token';
import { Branch, BranchI } from '../interfaces/branch';
import { BranchPublicInfo, BranchPublicInfoI } from '../interfaces/branch.public.info';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getUserBranchesApi(): Promise<Branch[]> {
  const url = `${envUrl}/branches/user`;

  const token = await getToken();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => {
      return response.data.map((item: BranchI) => new Branch(item));
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function getBranchesByBusinessApi(id: number): Promise<Branch[]> {
  const url = `${envUrl}/branches/business/${id}`;

  const token = await getToken();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => {
      return response.data.map((item: BranchI) => new Branch(item));
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function getBranchApi(id: number): Promise<Branch> {
  const url = `${envUrl}/branches/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => {
      return new Branch(response.data as BranchI);
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}


export async function getBranchPublicInfoApi(id: number): Promise<BranchPublicInfo> {
  const url = `${envUrl}/branches/publicInfo/${id}`;

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axios
    .get(url, config)
    .then((response) => {
      return new BranchPublicInfo(response.data as BranchPublicInfoI);
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function postBranchApi(
  data: Omit<BranchI, "id" | "createdAt" | "updatedAt">,
): Promise<Branch> {
  const url = `${envUrl}/branches`;
  const token = await getToken();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .post(url, data, config)
    .then((response) => {
      return new Branch(response.data as BranchI);
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function patchBranchApi(
  id: number,
  data: Partial<BranchI>,
): Promise<Branch> {
  const url = `${envUrl}/branches/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .patch(url, data, config)
    .then((response) => {
      return new Branch(response.data as BranchI);
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}
