import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Typography,
} from "@mui/material";
import { Layout as DashboardLayout } from "../layouts/dashboard/layout";
import { ReviewsWeek } from "../components/charts/reviews-week";
import { DatesFilter } from "../components/cards/dateFilter";
import { getReviewsApi } from "../api/reviews";
import { useEffect, useRef, useState } from "react";
import { ByEmotionPie } from "../components/charts/pie-emotion";
import { GroupsCard } from "../components/cards/groups-card";
import { TableGraphToggle } from "../components/cards/table-graph-toggle";
import { AllReviewsTable } from "../components/tables/all-reviews";
import { useAuthStore } from "../hooks/useAuthStore";
import React from "react";
import { ReviewResponseEntity } from "../interfaces/review/review-response";
import { Branch } from "../interfaces/branch";

const Groups: React.FC = () => {
  const [reviewsRawResponse, setReviewsRawResponse] = useState<
    ReviewResponseEntity | {}
  >({});
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [groups, setGroups] = useState<string[]>([]);
  const [tableGraphToggle, setTableGraphToggle] = useState<boolean>(false);
  const lastCalledRef = useRef(0);


  const { currentBranch } = useAuthStore() as { currentBranch: Branch };

  const handleDateChange = (start: string, end: string) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleGroupChange = (group: string) => {
    setSelectedGroup(group);
  };

  const handleToggle = () => {
    setTableGraphToggle(!tableGraphToggle);
  };

  useEffect(() => {
    const fetchData = async (start: Date, end: Date) => {
      try {
        const data: ReviewResponseEntity = selectedGroup
          ? await getReviewsApi(
              currentBranch.id,
              start,
              end,
              [],
              [selectedGroup],
            )
          : await getReviewsApi(currentBranch.id, start, end);
        setReviewsRawResponse(data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
 const now = Date.now();
    if (
      startDate !== '' &&
      endDate !== '' &&
      currentBranch.id &&
      now - lastCalledRef.current > 5000
    ) {
      lastCalledRef.current = now;
      const start = new Date(startDate);
      const end = new Date(endDate);
      fetchData(start, end);
    }
  }, [startDate, endDate, currentBranch, selectedGroup]);

  useEffect(() => {
    const fetchGroups = async (start: Date, end: Date) => {
      try {
        const data: ReviewResponseEntity= await getReviewsApi(currentBranch.id, start, end);
        setGroups(Object.keys(data['groupByGroup']));
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    if (startDate !== '' && endDate !== '' && currentBranch.id) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      fetchGroups(start, end);
    }
  }, [startDate, endDate, currentBranch]);

  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h1">{currentBranch.name}</Typography>
          <Grid container spacing={3}>
            <Grid xs={12} lg={9}>
              <DatesFilter
                handleFilter={handleDateChange}
                sx={{ height: "100%" }}
              />
            </Grid>
            <Grid xs={3}>
              <TableGraphToggle
                tableGraphToggle={tableGraphToggle}
                handleToggle={handleToggle}
              />
            </Grid>
            <Grid xs={12}>
              <GroupsCard
                handleSelectGroup={handleGroupChange}
                groups={groups}
                selectedGroup={selectedGroup}
              />
            </Grid>
            {tableGraphToggle ? (
              <>
                <Grid xs={12} md={6} lg={4}>
                  <ByEmotionPie
                    groupedByEmotion={reviewsRawResponse["groupByEmotion"]}
                    total={
                      reviewsRawResponse["groupByEmotion"]
                        ? Object.values(
                            reviewsRawResponse["groupByEmotion"] as Record<
                              string,
                              number
                            >,
                          ).reduce((acc: number, val: number) => acc + val, 0)
                        : 0
                    }
                    sx={{ height: "100%" }}
                  />
                </Grid>
                <Grid xs={12} lg={8}>
                  <ReviewsWeek
                    startDate={startDate}
                    endDate={endDate}
                    branchId={currentBranch.id}
                    group={selectedGroup}
                    sx={{ height: "100%" }}
                  />
                </Grid>
              </>
            ) : (
              <Grid xs={12}>
                <AllReviewsTable
                  byGroup={false}
                  startDate={startDate}
                  endDate={endDate}
                  branchId={currentBranch.id}
                  group={selectedGroup}
                  sx={{ height: "100%" }}
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Groups;
