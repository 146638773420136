import axios from 'axios';
import { getToken } from '../utils/token';
import { GroupsCollection, GroupsCollectionI } from '../interfaces/groups-collection';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getGroupsCollections(): Promise<GroupsCollection[]> {
  const url = `${envUrl}/groups-collections`;

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return axios
    .get(url, config)
    .then((response) => {
      return response.data.map(
        (item: GroupsCollectionI) => new GroupsCollection(item)
      );
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function getGroupsCollectionByIdApi(id: number): Promise<GroupsCollection> {
  const url = `${envUrl}/groups-collections/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => {
      return new GroupsCollection(response.data as GroupsCollectionI);
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}
