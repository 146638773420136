import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Chart } from "../chart";
import { useEffect, useState } from "react";
import { colors } from "../../utils/colors";
import React from "react";

const useChartOptions = (labels: string[]) => {
  const theme = useTheme();

  return {
    colors: colors,
    chart: {
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    labels,
    legend: {
      show: true,
      position: "bottom",
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      fillSeriesColor: false,
    },
  };
};

export const ByEmotionPie = ({
  sx,
  groupedByEmotion,
  total,
}: {
  sx?: object;
  groupedByEmotion: Record<string, number> | null;
  total: number;
}) => {
  const [chartData, setChartData] = useState<{
    labels: string[];
    series: number[];
  }>({ labels: [], series: [] });

  const chartOptions = useChartOptions(chartData.labels);

  useEffect(() => {
    if (!groupedByEmotion) return;
    const labels = Object.keys(groupedByEmotion);
    const series = labels.map((label) => groupedByEmotion[label] || 0);
    setChartData({ labels, series });
  }, [groupedByEmotion]);

  if (groupedByEmotion === null || total === 0) {
    return (
      <Card sx={sx}>
        <CardHeader title="Por Emoción" />
        <CardContent>
          <Typography variant="h6">No hay datos para mostrar</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={sx}>
      <CardHeader title="Por Emoción" />
      <CardContent>
        <Chart
          height={300}
          options={chartOptions}
          series={chartData.series}
          type="donut"
          width="100%"
        />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          sx={{ mt: 2 }}
          spacing={{ xs: 1, sm: 2 }}
          useFlexGap
          flexWrap="wrap"
        >
          {chartData.labels.map((label, index) => (
            <Box key={index}>
              <Typography sx={{ my: 1 }} variant="h6">
                {label}: {((chartData.series[index] * 100) / total).toFixed(0)}%
              </Typography>
            </Box>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};
