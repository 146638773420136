import { AuthState } from "../../interfaces/authstate";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from "../../interfaces/user";
import { Branch } from "../../interfaces/branch";
import { Business } from "../../interfaces/business";


export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        branches: [],
        business: {},
        currentBranch: {},
        isAuthenticated: false,
        isLoading: true,
        user: {},
    } as AuthState,
    reducers: {
        onChecking: (state) => {
            state.branches = [];
            state.business = {};
            state.currentBranch = {};
            state.isAuthenticated = false;
            state.isLoading = true;
            state.user = {};
        },
        onLogin: (state, { payload }: PayloadAction<User>) => {
            state.isAuthenticated = true;
            state.isLoading = false;
            state.user = payload;
        },
        onAddBranches: (state, { payload }: PayloadAction<Branch[]>) => {
            state.branches = payload;
        },
        onAddBusiness: (state, { payload }: PayloadAction<Business>) => {
            state.business = payload;
        },
        onChangeCurrentBranch: (state, { payload }: PayloadAction<Branch>) => {
            state.currentBranch = payload;
        },
        onLogout: (state) => {
            state.branches = [];
            state.business = {};
            state.currentBranch = {};
            state.isAuthenticated = false;
            state.isLoading = false;
            state.user = {};
        },
    },
});

export const { 
    onAddBranches,
    onAddBusiness,
    onChangeCurrentBranch,
    onChecking,
    onLogin,
    onLogout
} = authSlice.actions;

export default authSlice.reducer;
