import { useEffect, useRef, useState } from "react";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { patchBranchApi } from "../../api/branch";
import { getGroupsCollections } from "../../api/groupsCollections";
import { GroupsCollection } from "../../interfaces/groups-collection";
import React from "react";

export const LllmGroupsForm = ({ branch, handleSuccess }) => {
  const [modified, setModified] = useState(false);
  const [currentGroup, setCurrentGroup] = useState("");
  const [groups, setGroups] = useState<any[]>([]);
  const [groupsCollections, setGroupsCollections] = useState<
    GroupsCollection[]
  >([]);
    const lastCalledRef = useRef(0);


  const formik = useFormik({
    initialValues: {
      group: "",
      submit: null,
    },
    validationSchema: Yup.object({
      group: Yup.string().max(60).required("Inserta Nombre"),
    }),
    onSubmit: async (values, helpers) => {
      addGroup(values.group);
      helpers.resetForm();
    },
  });

  const addGroup = (group) => {
    if (!groups.includes(group)) {
      setModified(true);
      setGroups(sortAlphabetically([...groups, group]));
    }
  };

  const deleteGroup = (groupToDelete) => {
    setModified(true);
    setGroups(
      sortAlphabetically(groups.filter((group) => group !== groupToDelete)),
    );
  };

  const handleGroupsCollectionsChange = async (e, values) => {
    const groupName = values.props.value;
    setCurrentGroup(groupName);

    const foundGroupCollection = groupsCollections.find(
      (groupsCollection) => groupsCollection.name === groupName,
    );

    if (foundGroupCollection && foundGroupCollection.groups) {
      const groupsAsList = [...JSON.parse(foundGroupCollection.groups)];
      setGroups(sortAlphabetically(groupsAsList));
      setModified(true);
    } else {
      console.warn(`Group collection not found for name: ${groupName}`);
    }
  };

  const sortAlphabetically = (unsortedGroups) => {
    unsortedGroups.sort((a, b) => {
      let nameA = a.toUpperCase();
      let nameB = b.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return unsortedGroups;
  };

  const sortGroupsCollectionsList = (unsortedGroupsCollections) => {
    unsortedGroupsCollections.sort((a, b) => {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return unsortedGroupsCollections;
  };

  const updateGroups = async () => {
    if (groups.length < 3) return;
    try {
      await patchBranchApi(branch.id, { groups: JSON.stringify(groups) });
      handleSuccess();
      setModified(false);
    } catch (error) {
      console.error("Error updating groups", error);
    }
  };

  useEffect(() => {
    if (branch?.groups) {
      setGroups(sortAlphabetically([...JSON.parse(branch?.groups)]));
    }
    const fetchGroupsCollections = async () => {
      try {
        const data = await getGroupsCollections();
        setGroupsCollections(sortGroupsCollectionsList(data));
      } catch (error) {
        console.error('Error fetching parentReview:', error);
      }
    };
    const now = Date.now();
    if (branch?.id && now - lastCalledRef.current > 5000) {
      console.log('LllmGroupsForm - fetchData');
      lastCalledRef.current = now;
      fetchGroupsCollections();
    }
  }, [branch]);


  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader
          subheader="Grupos a través de los cuales se clasifican las reseñas"
          title="Grupos Temáticos"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box>
            <Grid container spacing={3}>
              <Stack
                sx={{
                  width: "50%",
                }}
              >
                <Select
                  fullWidth
                  value={currentGroup}
                  onChange={handleGroupsCollectionsChange}
                >
                  {groupsCollections.map((groupsCollection) => (
                    <MenuItem
                      key={groupsCollection.id}
                      value={groupsCollection.name}
                    >
                      {groupsCollection.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Stack
                useFlexGap
                flexWrap="wrap"
                direction="row"
                alignItems="center"
                spacing={3}
                sx={{
                  padding: "15px",
                  width: "100%",
                }}
              >
                {groups.map((group, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Button
                      onClick={() => deleteGroup(group)}
                      variant="contained"
                      color="error"
                      size="small"
                    >
                      {group}
                      <SvgIcon sx={{ marginLeft: "8px" }}>
                        <XMarkIcon />
                      </SvgIcon>
                    </Button>
                  </Stack>
                ))}
              </Stack>
              {groups.length < 3 && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  Al menos se necesitan 3 grupos
                </Typography>
              )}
              <Grid xs={6}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TextField
                    fullWidth
                    label="Nombre del grupo"
                    name="group"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.group}
                  />
                  <Button variant="contained" size="small" type="submit">
                    Agregar Grupo
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            {formik.errors.submit && (
              <Typography color="error" sx={{ mt: 3 }} variant="body2">
                {formik.errors.submit}
              </Typography>
            )}
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          {modified && (
            <Button variant="contained" onClick={updateGroups}>
              Modificar Categorías
            </Button>
          )}
        </CardActions>
      </Card>
    </form>
  );
};
