// src/interfaces/review.interface.ts

export interface ReviewI {
    id: number;
    rawReviewId: number;
    branchId: number;
    sentiment: string;
    group: string;
    emotion: string;
    score: number;
    improvement: string;
    createdAt: Date;
    summary: string;
  }


export class Review implements ReviewI {
  id: number;
  rawReviewId: number;
  branchId: number;
  sentiment: string;
  group: string;
  emotion: string;
  score: number;
  improvement: string;
  createdAt: Date;
  summary: string;

  constructor(review: ReviewI) {
    this.id = review.id;
    this.rawReviewId = review.rawReviewId;
    this.branchId = review.branchId;
    this.sentiment = review.sentiment;
    this.group = review.group;
    this.emotion = review.emotion;
    this.score = review.score;
    this.improvement = review.improvement;
    this.createdAt = review.createdAt;
    this.summary = review.summary;
  }
}
