export interface AuthEntityI {
    accessToken: string;
  }

  
  export class AuthEntity implements AuthEntityI {
    accessToken: string;
  
    constructor(authEntityI: AuthEntityI) {
      this.accessToken = authEntityI.accessToken;
    }
  }
  