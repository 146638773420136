import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '../theme';
import { createEmotionCache } from '../utils/create-emotion-cache';
import { Provider } from 'react-redux';
import { store } from '../store';
import 'simplebar-react/dist/simplebar.min.css';
import React, { ReactNode } from 'react';

const clientSideEmotionCache = createEmotionCache();

type AppWrapperProps={
  children: ReactNode
}

const AppWrapper = ({ children}:AppWrapperProps) => {
  const theme = createTheme();

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </Provider>
      </LocalizationProvider>
    </CacheProvider>
  );
};

export default AppWrapper;
