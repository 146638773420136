import { format } from "date-fns";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Scrollbar } from "../scrollbar";
import { useEffect, useState } from "react";
import { dayOfWeekMapper } from "../../utils/dayOfWeekMapper";
import { getReviewsByTimeApi } from "../../api/reviews";
import React from "react";

interface ReviewsByDateTableProps {
  sx?: object;
  startDate: string;
  endDate: string;
  branchId: number;
}

interface SentimentData {
  Positivo: number;
  Negativo: number;
  Neutral: number;
}

interface GroupedBySentiment {
  [key: string]: SentimentData;
}

export const ReviewsByDateTable = ({
  sx,
  startDate,
  endDate,
  branchId,
}: ReviewsByDateTableProps): JSX.Element => {
  const [groupedBySentiment, setGroupedBySentiment] =
    useState<GroupedBySentiment>({});

  useEffect(() => {
    const fetchData = async (start: Date, end: Date) => {
      try {
        const data = await getReviewsByTimeApi(
          branchId,
          start,
          end,
          "sentiment",
          "date",
        );
        setGroupedBySentiment(data as GroupedBySentiment);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
    if (startDate !== "" && endDate !== "" && branchId) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      fetchData(start, end);
    }
  }, [startDate, endDate, branchId]);

  return (
    <Card sx={sx}>
      <CardHeader title="Resumen" />
      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection="desc">Fecha</TableCell>
                <TableCell>Positivo</TableCell>
                <TableCell>Negativo</TableCell>
                <TableCell>Neutral</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(groupedBySentiment).map((dateTag) => {
                const date = format(new Date(dateTag), "dd/MM/yyyy");
                const dayOfTheWeek = format(new Date(dateTag), "EEEE");
                return (
                  <TableRow hover key={dateTag}>
                    <TableCell>
                      {dayOfWeekMapper[dayOfTheWeek]}, {date}
                    </TableCell>
                    <TableCell>
                      {groupedBySentiment[dateTag]["Positivo"]}
                    </TableCell>
                    <TableCell>
                      {groupedBySentiment[dateTag]["Negativo"]}
                    </TableCell>
                    <TableCell>
                      {groupedBySentiment[dateTag]["Neutral"]}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Divider />
    </Card>
  );
};
