import axios from 'axios';
import { getToken } from '../utils/token';
import { Attendant, AttendantI } from '../interfaces/attendant';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getAttendantsByBranchApi(id: number, status: boolean[] = []): Promise<Attendant[]> {
  let url = `${envUrl}/attendants/branch/${id}/`;
  if (status.length) {
    url += `?status=${JSON.stringify(status)}`;
  }

  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .get(url, config)
    .then((response) => response.data.map((attendant: AttendantI) => new Attendant(attendant)))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function getAttendantByIdApi(id: number): Promise<Attendant> {
  const url = `${envUrl}/attendants/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .get(url, config)
    .then((response) => new Attendant(response.data as AttendantI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function postAttendantApi(
  data: Omit<AttendantI, 'id' | 'createdAt' | 'updatedAt'>
): Promise<Attendant> {  
  const url = `${envUrl}/attendants`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .post(url, data, config)
    .then((response) => new Attendant(response.data as AttendantI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function patchAttendantApi(id: number, data: Partial<AttendantI>):   Promise<Attendant> {
  const url = `${envUrl}/attendants/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .patch(url, data, config)
    .then((response) => new Attendant(response.data as AttendantI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function deleteAttendantApi(id: number):  Promise<Attendant> {
  const url = `${envUrl}/attendants/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .delete(url, config)
    .then((response) => new Attendant(response.data as AttendantI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}
