import { Layout as DashboardLayout } from '../layouts/dashboard/layout';
import { Box, Container, Stack, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { ToastSuccessSync } from '../components/shared/Toast';
import { useAuthStore } from '../hooks/useAuthStore';
import { ChangePasswordForm } from '../components/forms/change-password-form';
import { User } from '../interfaces/user';
import React from 'react';

const MyInfo = () => {
  const { user } = useAuthStore() as {user:User};

  const handleSuccess = () => {
    ToastSuccessSync('Procedimiento Exitoso');
  };

  return (
    <DashboardLayout>
      <ToastContainer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">{user.name}</Typography>
              </Stack>
            </Stack>
            <ChangePasswordForm user={user} handleSuccess={handleSuccess} />
          </Stack>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default MyInfo;
