import axios from "axios";
import { getToken } from "../utils/token";
import {
  ReviewResponseEntity,
  ReviewResponseEntityI,
} from "../interfaces/review/review-response";
import { RawReview, RawReviewI } from "../interfaces/review/raw-review";
import { Review, ReviewI } from "../interfaces/review/review";

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getReviewsApi(
  branchId: number,
  start: Date,
  end: Date,
  emotions: string[] = [],
  groups: string[] = [],
  sentiments: string[] = [],
  attendants: string[] = [],
  spots: string[] = [],
  sort?: string,
  limit?: number,
  page?: number,
): Promise<ReviewResponseEntity> {
  const allowedSorts = [
    "dateAsc",
    "dateDesc",
    "scoreAsc",
    "scoreDesc",
    undefined,
  ];

  if (!allowedSorts.includes(sort)) {
    throw new Error('Invalid sort parameter');
  }

  let startDate = new Date(start).toISOString();
  let endDate = new Date(end).toISOString();
  let url = `${envUrl}/reviews/branch/${branchId}/?start=${startDate}&end=${endDate}`;
  if (emotions.length) {
    url += `&emotions=${JSON.stringify(emotions)}`;
  }
  if (groups.length) {
    url += `&groups=${JSON.stringify(groups)}`;
  }
  if (sentiments.length) {
    url += `&sentiments=${JSON.stringify(sentiments)}`;
  }
  if (attendants.length) {
    url += `&attendants=${JSON.stringify(attendants)}`;
  }
  if (spots.length) {
    url += `&spots=${JSON.stringify(spots)}`;
  }
  if (sort) {
    url += `&sort=${sort}`;
  }
  if (limit) {
    url += `&limit=${limit}`;
  }
  if (page) {
    url += `&page=${page}`;
  }

  const token = await getToken();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => {
      const responseData: ReviewResponseEntityI = response.data;
      return new ReviewResponseEntity(responseData);
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function getReviewsByTimeApi(
  branchId: number,
  start: Date,
  end: Date,
  groupingUnit: string,
  timeUnit: string,
  emotions: string[] = [],
  groups: string[] = [],
  sentiments: string[] = [],
): Promise<object> {
  const allowedGroupingUnits = ["emotion", "group", "sentiment"];
  const allowedTimeUnits = ["date", "day", "hour", "month"];

  if (!allowedGroupingUnits.includes(groupingUnit)) {
    throw new Error("Invalid grouping unit parameter");
  }

  if (!allowedTimeUnits.includes(timeUnit)) {
    throw new Error("Invalid time unit parameter");
  }

  const startDate = new Date(start).toISOString();
  const endDate = new Date(end).toISOString();
  let url = `${envUrl}/reviews/branch/byTime/${branchId}/?start=${startDate}&end=${endDate}&groupingUnit=${groupingUnit}&timeUnit=${timeUnit}`;
  if (emotions.length) url += `&emotions=${JSON.stringify(emotions)}`;
  if (groups.length) url += `&groups=${JSON.stringify(groups)}`;
  if (sentiments.length) url += `&sentiments=${JSON.stringify(sentiments)}`;

  const token = await getToken();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => response.data)
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function getRawReviewByIdApi(
  rawReviewId: number,
): Promise<RawReview> {
  const url = `${envUrl}/reviews/rawReview/${rawReviewId}`;

  const token = await getToken();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, config)
    .then((response) => new RawReview(response.data as RawReviewI))
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
}

export async function postReviewApi(
  review: Omit<RawReview, 'id' | 'branchId' | 'createdAt'>,
  branchId: number,
): Promise<Review> {
  const url = `${envUrl}/reviews/branch/${branchId}`;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(url, review , config)
    .then((response) => new Review(response.data as ReviewI))
    .catch((err) => {
            // This is better than throw err
      // since this will allow us to read the server's error message(s)
      throw new Error(err.response?.data?.message || err.message);
    });
}
